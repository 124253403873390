<template>
    <div v-if="platform === 'web'" class="w-full h-full flex items-center justify-center bg-gray-200 module">
        <div class="box p-0 w-1/3 h-1/2 flex flex-col items-center justify-center shadow-xl">
            <img src="@/assets/images/logoEy.svg">
            <div class="btn-aux text-xss" @click="goToLogin()">
                Login With Azure
            </div>
        </div>
    </div>
    <div v-else class="w-full h-full flex items-center justify-center bg-gray-200 module">
        <div class="box p-0 w-auto p-10 h-auto flex flex-col items-center justify-between shadow-xl">
            <img src="@/assets/images/logoEy.svg" class="h-72 mb-16">
            <div class="btn-aux text-xss mb-6" @click="goToLogin()">
                Login With Azure
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
                platform: ''
            };
        },
        mounted: function () {
            this.platform = this.detectDevice
            localStorage.setItem('platform', this.detectDevice())
        },
        methods: {
            detectDevice() {
                return this.$platform()
            },
            goToLogin(){
                window.location.replace(process.env.VUE_APP_SAML_REDIRECTION);
            }
        }
    }
</script>

